@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-white;
  @apply flex;
  @apply flex-col;
}

/**
 * Necessary to style the (non ag-grid) List component with alternating row colors & hover
 */
[role='row']:nth-child(even):not(.ag-root [role='row']) {
  @apply bg-gray-50;
}
[role='row']:not(.ag-root [role='row']):hover {
  @apply bg-blue-50;
}

.dots-loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 2s infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.reflex-container.horizontal > .reflex-splitter,
.reflex-container.vertical > .reflex-splitter {
  @apply border-transparent bg-clip-padding z-[11];
}
.reflex-container.horizontal > .reflex-splitter {
  @apply py-[1px] border-y-[6px] my-[-6px];
}
.reflex-container.vertical > .reflex-splitter {
  @apply px-[1px] border-x-[6px] mx-[-6px];
}

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active,
.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  @apply bg-indigo-400 bg-clip-padding border-transparent;
  transition: background-color 0.3s ease-out;
}

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  @apply border-y-[6px];
}

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  @apply border-x-[6px];
}

@media print {
  @page {
    margin: 0;
    padding-top: 10mm;
  }

  /* Fixes Print Notebook in Firefox */
  .cell-editor {
    padding: 0 !important;
    margin: 0 !important;
  }

  .react-codemirror2 {
    height: auto !important;
  }

  .CodeMirror-scroll {
    height: auto !important;
  }
}

/* Necessary for the add cell lines */
.add-cell:before,
.add-cell::after {
  top: 1px;
  display: block;
  flex: 1 1 auto;
  height: 1px;
  margin-right: 4px;
  margin-left: 4px;
  background-color: #ebebeb;
  content: '';
  margin-top: 9px;
}

/* Necessary so autofill does not change the background color of input */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* 
This's the way to set max height according to CodeMirror's author
https://discuss.codemirror.net/t/how-to-set-max-height-of-the-editor/2882
*/
.txn-details-query .cm-editor {
  max-height: 40em;
}
.txn-details-query .cm-scroller {
  overflow: auto;
}
