.rmdp-wrapper .rmdp-day.rmdp-selected > span:not(.highlight),
.rmdp-wrapper .rmdp-day.rmdp-range > span {
    background-color: theme('colors.red-orange.900');
    color: white;
    box-shadow: none;
}

.rmdp-wrapper .rmdp-day.rmdp-selected:not(.rmdp-disabled):not(.rmdp-day-hidden) > span:hover,
.rmdp-wrapper .rmdp-day.rmdp-range:not(.rmdp-disabled):not(.rmdp-day-hidden) > span:hover {
    background-color: theme('colors.red-orange.700');
    color: white;
}

.rmdp-wrapper .rmdp-day {
    transition: 200ms;
}

.rmdp-wrapper .rmdp-day > span {
    color: theme('colors.gray.700');
    transition: 200ms;
    border-radius: 4px;
}

.rmdp-wrapper .rmdp-day.rmdp-today > span {
    color: theme('colors.gray.700');
    background-color: theme('colors.gray.200');
}

.rmdp-wrapper .rmdp-day.rmdp-deactive:not(.rmdp-range) > span {
    color: theme('colors.gray.400');
}

.rmdp-wrapper .rmdp-day.rmdp-disabled > span {
    color: theme('colors.gray.300');
}

.rmdp-wrapper .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) > span:hover {
    color: theme('colors.gray.700');
    background-color: theme('colors.red-orange.200');
}

.rmdp-wrapper .rmdp-range {
    background: none;
    box-shadow: none;
}

.rmdp-wrapper .rmdp-range > span {
    color: white;
}

.rmdp-wrapper .rmdp-day.rmdp-range.rmdp-today > span {
    background-color: theme('colors.red-orange.700');
    color: white;
;
}

.rmdp-wrapper .rmdp-range.start {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.rmdp-wrapper .rmdp-range.end {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.rmdp-wrapper .rmdp-week-day {
    color: theme('colors.red-orange.900');
}

.rmdp-wrapper .rmdp-arrow-container {
    border-radius: 4px;
    width: 30px;
    height: 30px;
    margin: 0;
    transition: 200ms;
}

.rmdp-wrapper .rmdp-arrow {
    align-self: center;
    padding: 3px;
    margin: 0;
    border: solid theme('colors.gray.700');
    border-width: 0 2px 2px 0;
    transition: 200ms;
}

.rmdp-wrapper .rmdp-arrow-container.disabled .rmdp-arrow,
.rmdp-wrapper .rmdp-arrow-container.disabled:hover .rmdp-arrow {
    border: solid theme('colors.gray.300');
    border-width: 0 2px 2px 0;}

.rmdp-wrapper .rmdp-arrow-container:hover {
    background-color: transparent;
    box-shadow: none;
}

.rmdp-wrapper .rmdp-arrow-container:hover .rmdp-arrow {
    border: solid theme('colors.red-orange.900');
    border-width: 0 2px 2px 0;
}

.rmdp-wrapper .rmdp-header {
    margin: 0;
    padding: 4px;
}

.rmdp-wrapper .rmdp-time-picker {
    padding: 5px 0;
}

.rmdp-time-picker div .rmdp-am {
    justify-content: center;
}

.rmdp-time-picker div input:focus {
    user-select: all;
    box-shadow: none;
}

.rmdp-wrapper .rmdp-ym .rmdp-day span {
    border-radius: 4px;
}
